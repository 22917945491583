import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import Author from './assets/author.png';

function App() {
  return (
    <Box p={2}>
      <Typography variant="h4" align="center" gutterBottom>Chosen4Freedom</Typography>
      <Typography variant="subtitle1" align="center" color="GrayText">Site Coming Soon!</Typography>
      <Box my={2} display="flex" justifyContent="center">
        <img src={Author} alt="chosen-4-freedom-author" style={{ maxWidth: "500px", width: "80%", borderRadius: "5px" }} />
      </Box>
      <Typography variant="subtitle1" align="center">Chosen4Freedom.org © 2020. All Rights Reserved</Typography>
    </Box>
  );
}

export default App;
