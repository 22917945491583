import { createTheme } from "@mui/material"

const theme = createTheme({
  palette: {
    background: {
      default: '#49599a'
    }
  }
})

export default theme